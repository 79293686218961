















import Select from "@/components/custom/select/Select.vue";
import { debounceProcess } from "@/helpers/debounce";
import { useProduct } from "@/hooks";
import { FIRST_PAGE } from "@/mixins/MQueryPage.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { ONE } from "@/models/constant/global.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ProductStockResponseDto } from "@interface/master-product";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    Select,
  },
})
export default class SelectWoPart extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: String, default: "code" })
  optionLabel!: "code" | "name";

  options: Option<ProductStockResponseDto>[] = [];
  loading = false;

  pagination = {
    page: FIRST_PAGE,
    search: "",
  };

  /**
   * @description
   * flag to determine if data is all loaded
   */
  allLoaded = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    const params = this.buildParams();
    this.fetchOptions(params);
  }

  findLabel(
    { code, name, description }: ProductStockResponseDto,
    field: "code" | "name"
  ): string {
    if (field === "name") {
      return `${name} (${description})`;
    }
    return code;
  }

  async fetchOptions(params: RequestQueryParamsModel): Promise<void> {
    const { findAllProductInStock } = useProduct();
    try {
      this.loading = true;
      const res = await findAllProductInStock(params);
      this.allLoaded = res.currentPage + ONE === res.totalPages;
      const options = res.data.map(item => ({
        label: this.findLabel(item, this.optionLabel),
        value: item.id,
        key: item.id,
        meta: item,
      }));

      const copy = [...this.options];
      this.options = [...copy, ...options];
    } finally {
      this.loading = false;
    }
  }

  clearOptions(): void {
    this.options = [];
    this.pagination.page = FIRST_PAGE;
    this.allLoaded = false;
  }

  buildParams(): RequestQueryParamsModel {
    const params = new RequestQueryParams();
    const { page, search } = this.pagination;

    params.search = search;
    params.page = page - ONE;

    return params;
  }

  onSearch(val?: string): void {
    const { searchProductInStock } = useProduct();
    this.clearOptions();

    this.pagination.search = searchProductInStock({
      [this.optionLabel]: val,
      desc: val,
      type: val,
      categoryName: val,
    });

    this.fetchOptions(this.buildParams());
  }

  onChange(e?: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onScrollEnd(): void {
    if (this.loading || this.allLoaded) return;
    this.pagination.page += ONE;
    this.fetchOptions(this.buildParams());
  }

  findOption(value?: string): Option<ProductStockResponseDto> | undefined {
    return this.options.find(e => value === e.value);
  }
}
