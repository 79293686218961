













import Select from "@/components/custom/select/Select.vue";
import { useLocalFilter } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { WoUnitRow } from "@/store/workOrder.store";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {
    Select,
  },
})
export default class SelectUnitSparepart extends Vue {
  useLocalFilter = useLocalFilter;

  @Prop({ required: true, type: String, default: "" })
  value!: string | undefined;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: Array as () => Option[], default: [] })
  options!: Option<WoUnitRow>[];

  emits(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  findOption(val: string): Option<WoUnitRow> | undefined {
    return this.options.find(item => item.value === val);
  }
}
