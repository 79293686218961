















import { SearchBuilder } from "@/builder";
import Select from "@/components/custom/select/Select.vue";
import { debounceProcess } from "@/helpers/debounce";
import { useInventory } from "@/hooks";
import { FIRST_PAGE } from "@/mixins/MQueryPage.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { ONE } from "@/models/constant/global.constant";
import { RequestQueryParamsModel } from "@interface/http.interface";
import { InventoryLineResponseDto } from "@interface/inventory";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    Select,
  },
})
export default class SelectPartLocation extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: String, default: "" })
  productId!: string | undefined;

  @Prop({ required: false, type: String, default: "" })
  branchId!: string | undefined;

  @Prop({ required: false, type: String, default: "" })
  uomId!: string | undefined;

  loading = false;

  options: Option<InventoryLineResponseDto>[] = [];

  pagination = {
    page: FIRST_PAGE,
    search: "",
  };

  allLoaded = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    if (this.productId && this.branchId && this.uomId) {
      const params = this.buildParams();
      this.fetchOptions(params);
    }
  }

  @Watch("productId")
  onChangeProduct(newVal?: string): void {
    this.options = [];
    if (!newVal || !this.branchId || !this.uomId) return;
    this.fetchOptions(this.buildParams());
  }

  @Watch("branchId")
  onChangeBranch(newVal?: string): void {
    this.options = [];
    if (!newVal || !this.productId || !this.uomId) return;
    this.fetchOptions(this.buildParams());
  }

  @Watch("uomId")
  onChangeUom(newVal?: string): void {
    this.options = [];
    if (!newVal || !this.productId || !this.branchId) return;
    this.fetchOptions(this.buildParams());
  }

  async fetchOptions(params: RequestQueryParamsModel): Promise<void> {
    if (this.loading) return;
    const { findAllInventoryLine, toRackOptions } = useInventory();
    try {
      this.loading = true;
      const res = await findAllInventoryLine(params);
      this.allLoaded = res.currentPage + ONE === res.totalPages;
      const copy = [...this.options];
      const options = toRackOptions(res.data);
      this.options = [...copy, ...options];
    } finally {
      this.loading = false;
    }
  }

  resetState(): void {
    this.pagination.page = FIRST_PAGE;
    this.options = [];
  }

  onSearch(e = ""): void {
    const { buildQuerySearch } = useInventory();
    const { filter: querySearch } = buildQuerySearch({
      rackName: e,
    });

    if (!this.branchId || !this.productId) return;
    this.resetState();
    this.pagination.search = querySearch;
    this.fetchOptions(this.buildParams());
  }

  findOption(val?: string): Option<InventoryLineResponseDto> | undefined {
    return this.options.find(item => item.value === val);
  }

  onChange(e?: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onScrollEnd(): void {
    if (this.loading || this.allLoaded) return;
    this.pagination.page += ONE;
    this.fetchOptions(this.buildParams());
  }

  /**
   * @description
   * create params based on product, branch, available > 0
   */
  buildParams(): RequestQueryParamsModel {
    const params = new RequestQueryParams();
    const builder = new SearchBuilder();
    const { buildQuerySearch } = useInventory();
    const { search: persistQuery, page } = this.pagination;
    const findAvailableProductQuery = buildQuerySearch({
      isAvailable: true,
    }).filter;
    const queries: Array<string> = [findAvailableProductQuery];

    if (this.productId) {
      const findByProductQuery = buildQuerySearch({
        productId: this.productId,
      }).filter;

      queries.push(findByProductQuery);
    }

    if (this.branchId) {
      const findByBranchQuery = buildQuerySearch({
        branchId: this.branchId,
      }).filter;
      queries.push(findByBranchQuery);
    }

    if (this.uomId) {
      const findByUomQuery = buildQuerySearch({
        uomId: this.uomId,
      }).filter;
      queries.unshift(findByUomQuery);
    }

    if (persistQuery) {
      queries.unshift(persistQuery);
    }

    params.page = page - ONE;
    params.search = queries.join(builder.AND);

    return params;
  }
}
