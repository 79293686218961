










































































































import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectCustomer from "@/components/custom/select/SelectCustomer.vue";
import SelectMechanic from "@/components/custom/select/SelectMechanic.vue";
import { useContactData, useDisabledBackDate } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { FormValue, State } from "@/store/workOrder.store";
import {
  AddressDataDto,
  DetailContactDataDto,
  ListContactDataDto,
} from "@interface/contact-data";
import { AssetResponseDto } from "@interface/master-asset";
import { FormModel } from "ant-design-vue";
import { Component, Ref, Vue } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";
import SelectUnit from "./SelectUnit.vue";

@Component({
  components: {
    SelectBranch,
    SelectUnit,
    SelectCustomer,
    SelectMechanic,
  },
  computed: {
    ...mapState({
      store: (st: any) => st.workOrderStore,
    }),
  },
  methods: {
    ...mapMutations({
      setForm: "workOrderStore/setForm",
    }),
  },
})
export default class FormHeader extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  useDisabledBackDate = useDisabledBackDate;

  store!: State;
  setForm!: (fields: Partial<FormValue>) => void;

  @Ref("formModel")
  formModel!: FormModel;

  formRules = {
    branchWarehouseId: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    workOrderDate: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    mechanicId: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    scheduleOrder: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    description: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
  };

  custAddresses: AddressDataDto[] = [];

  getDetailCust(custId: string, cb: (dt: DetailContactDataDto) => void): void {
    const { findOne } = useContactData();
    findOne(custId).then(cb);
  }

  /**
   * @description
   * get pic name from selected customer address
   */
  getDefaultPic(
    customerAddress: string,
    shippingAddress: AddressDataDto[]
  ): string {
    const address = shippingAddress.find(
      item =>
        item.shipTo &&
        item.location?.toUpperCase() === customerAddress?.toUpperCase()
    );
    return address?.picName ?? "";
  }

  setDefaultPic(addresses: AddressDataDto[]): void {
    this.setForm({
      picName: this.getDefaultPic(this.store.form.customerAddress, addresses),
    });
  }

  onChangeUnitCode(ev?: Option<AssetResponseDto>): void {
    this.setForm({
      customerId: ev?.meta?.customerId ?? "",
      customerName: ev?.meta?.customerName ?? "",
      customerAddress: ev?.meta?.customerLocation ?? "",
    });

    if (!this.store.form.customerId) return;
    this.getDetailCust(this.store.form.customerId, ({ addressDataList }) => {
      this.setDefaultPic(addressDataList);
    });
  }

  onChangeCustomer(ev?: Option<ListContactDataDto>): void {
    this.setForm({
      customerAddress: "",
      picName: "",
      unitCode: "",
    });
  }
}
