
















































import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { State, WoUnitRow } from "@/store/workOrder.store";
import { AssetResponseDto, SegmentResponseDto } from "@interface/master-asset";
import { Component, Mixins } from "vue-property-decorator";
import { mapActions, mapState } from "vuex";
import SelectWorkUnit from "./SelectWorkUnit.vue";

@Component({
  components: {
    SelectWorkUnit,
  },
  computed: {
    ...mapState({
      store: (st: any) => st.workOrderStore,
    }),
  },
  methods: {
    ...mapActions({
      addUnit: "workOrderStore/addUnit",
      removeUnits: "workOrderStore/removeUnits",
    }),
  },
})
export default class FormUnit extends Mixins(MNotificationVue) {
  store!: State;
  addUnit!: () => void;
  removeUnits!: (keys: Array<string>) => void;

  columns = [
    {
      title: this.$t("lbl_number_short"),
      dataIndex: "no",
      width: 75,
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      width: 200,
      scopedSlots: { customRender: "unitCode" },
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      width: 200,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_category"),
      dataIndex: "assetCategory",
      width: 200,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_capacity"),
      dataIndex: "capacity",
      width: 100,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_specification"),
      dataIndex: "unitSpecification",
      width: 200,
      scopedSlots: { customRender: "unitSpecification" },
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      width: 200,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_model"),
      dataIndex: "model",
      width: 200,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_location"),
      dataIndex: "location",
      width: 300,
      scopedSlots: { customRender: "nullable" },
    },
  ];

  selectedRowKeys: Array<string> = [];

  onSelectChange(keys: Array<string>): void {
    this.selectedRowKeys = keys;
  }

  handleRemoveUnits(): void {
    this.showConfirmationDeleteItems(() => {
      this.removeUnits(this.selectedRowKeys);
      this.selectedRowKeys = [];
    });
  }

  getAssetCategory(segments: SegmentResponseDto[]): string {
    const [first, second] = segments;
    return `${first?.first ?? ""}.${second?.first ?? ""}`;
  }

  getAssetLocation(dt?: AssetResponseDto): string {
    const branch = dt?.assetLocation.warehouse.branchWarehouse.name ?? "";
    const warehouse = dt?.assetLocation.warehouse.name ?? "";
    const rack = dt?.assetLocation.name ?? "";

    return `${branch}, ${warehouse}, ${rack}`;
  }

  onChangeWorkUnit(row: WoUnitRow, dt?: Option<AssetResponseDto>): void {
    row.unitCode = dt?.label ?? "";
    row.assetId = dt?.meta?.id ?? "";
    row.serialNumber = dt?.meta?.serialNumber ?? "";
    row.unitSpecification = dt?.meta?.description ?? "";
    row.model = dt?.meta?.model ?? "";
    row.type = dt?.meta?.type ?? "";
    row.capacity = dt?.meta?.capacity ?? "";

    let formatCategory = "";
    if (dt && dt.meta && dt.meta.assetCategory) {
      formatCategory = this.getAssetCategory(dt.meta.assetCategory.segments);
    }
    row.assetCategory = formatCategory;

    row.location = this.getAssetLocation(dt?.meta);
  }
}
