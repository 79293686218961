


















import { FormHeader, FormPart, FormUnit } from "@/components/WorkOrder";
import { useWorkOrder } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { WorkOrderCreateRequestDto } from "@/models/interface/work-order";
import { FormValue, State } from "@/store/workOrder.store";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { mapState } from "vuex";

@Component({
  components: {
    FormHeader,
    FormUnit,
    FormPart,
  },
  computed: {
    ...mapState({
      store: (st: any) => st.workOrderStore,
    }),
  },
})
export default class FormPage extends Mixins(MNotificationVue) {
  @Ref("formHeader")
  formHeader!: any;

  store!: State;

  loading = false;

  handleSubmit(): void {
    this.validateForm();
  }

  handleBack(): void {
    this.$router.push({
      name: "logistic.work-order",
      query: this.$route.query,
    });
  }

  async handleCreate(form: FormValue): Promise<void> {
    const { create, mapFormToCreateDto } = useWorkOrder();
    try {
      const req: WorkOrderCreateRequestDto = mapFormToCreateDto(form);
      this.loading = true;
      const { documentNumber } = await create(req);
      this.showNotifSuccess("notif_create_success", {
        documentNumber,
      });
      this.handleBack();
    } finally {
      this.loading = false;
    }
  }

  isUnitsInvalid(): boolean {
    const isEmpty = !this.store.form.units.length;
    const invalidUnit = !!this.store.form.units.find(
      item => !item.assetId || !item.unitCode
    );
    return isEmpty || invalidUnit;
  }

  isSparepartInvalid(): boolean {
    const isEmpty = !this.store.form.spareParts.length;
    const invalidSparepart = !!this.store.form.spareParts.find(
      item =>
        !item.unitCode ||
        !item.productId ||
        !item.productUomId ||
        !item.productLocationId ||
        !item.qtyWorkOrder
    );
    return isEmpty || invalidSparepart;
  }

  async validateForm(): Promise<void> {
    const headerModel: FormModel = this.formHeader.formModel;
    try {
      await headerModel.validate();

      const unitInvalid: boolean = this.isUnitsInvalid();
      if (unitInvalid) {
        this.showNotifWarning("notif_work_order_units_invalid");
        return;
      }

      if (this.isSparepartInvalid()) {
        this.showNotifWarning("notif_work_order_spareparts_invalid");
        return;
      }

      this.handleCreate(this.store.form);
    } catch (error) {
      this.showNotifWarning("notif_validation_error");
    }
  }
}
