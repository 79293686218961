














import Select from "@/components/custom/select/Select.vue";
import { useLocalFilter, useProduct } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { ProductUomConversionDto } from "@interface/master-product";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    Select,
  },
})
export default class SelectPartUom extends Vue {
  useLocalFilter = useLocalFilter;

  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: String, default: "" })
  productId!: string | undefined;

  loading = false;

  options: Option<ProductUomConversionDto>[] = [];

  created(): void {
    if (this.productId) {
      this.fetchOptions(this.productId);
    }
  }

  @Watch("productId")
  onChangeProduct(newVal?: string): void {
    this.options = [];
    if (newVal) this.fetchOptions(newVal);
  }

  async fetchOptions(productId: string): Promise<void> {
    const { findById, toOptionsUomConv } = useProduct();
    try {
      this.loading = true;
      const { uomConversions } = await findById(productId);
      this.options = toOptionsUomConv(uomConversions);
    } finally {
      this.loading = false;
    }
  }

  findOption(val?: string): Option<ProductUomConversionDto> | undefined {
    return this.options.find(item => item.value === val);
  }

  onChange(e?: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }
}
